.bg-grey {
    background-color: #343a40 !important
}

.navbar-brand {
    margin-right: 30px !important;
}

.jumbotron {
    background-color: #e9ecef;
    margin-bottom: 2rem;
    padding: 2rem 2rem;
}

.margin-top {
    margin-top: 2rem;
}

.margin-bottom {
    margin-bottom: 2rem;
}

.CodeMirror {
    height: auto !important;
}

.small-print {
    font-size: 8pt;
}

pre {
    tab-size: 2;
    -moz-tab-size: 2;
    font-size: 87.5%;
    color: #e83e8c;
    margin-bottom: 0;
}

hr {
    opacity: .1 !important;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}